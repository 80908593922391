import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  LoadTabsSellerInfo,
  LoadTabsSellerInfoFailure,
  LoadTabsSellerInfoSuccess,
  TabsSellerInfoActionTypes,
} from '@sales-orders-app/modules/orders/store/tabs-seller-info/tabs-seller-info.actions';
import { TabsSellerInfoService } from '../../services/tabs-seller-info.service';

@Injectable()
export class TabsSellerInfoEffects {
  loadTabsSellerInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO),
      map((action: LoadTabsSellerInfo) => action.payload),
      switchMap(({ sellerOrganizationIds }) =>
        this.tabsSellerInfoService.getSellerDetails(sellerOrganizationIds).pipe(
          map(sellerInfos => new LoadTabsSellerInfoSuccess({ sellerInfos })),
          catchError(error => of(new LoadTabsSellerInfoFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private tabsSellerInfoService: TabsSellerInfoService) {}
}
