import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  AFTERSALES_ADMIN_API
} from '@sales-orders-app/modules/orders/constants/api';
import { TabsSellerInfo } from '@sales-orders-app/modules/orders/models/tabs-seller-info.model';
import { map, shareReplay } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class TabsSellerInfoService {

  private sellerCache = new Map<string, TabsSellerInfo>();

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
  ) {}

  getSellerDetails(sellerIds: string[]): Observable<TabsSellerInfo[]> {
    const cachedSellers: TabsSellerInfo[] = [];
    const missingSellerIds: string[] = [];

    sellerIds.forEach((id) => {
      if (this.sellerCache.has(id)) {
        cachedSellers.push(this.sellerCache.get(id)!);
      } else {
        missingSellerIds.push(id);
      }
    });
    if (missingSellerIds.length === 0) {
      return of(cachedSellers);
    }

    return forkJoin(
      missingSellerIds.map((id) => this.fetchSellerFromServer(id))
    ).pipe(
      map((newSellers) => {
        newSellers.forEach((seller, index) => {
          this.sellerCache.set(missingSellerIds[index], seller);
        });
        return [...cachedSellers, ...newSellers];
      })
    );
  }

  private fetchSellerFromServer(sellerOrganizationId: string): Observable<TabsSellerInfo> {
    const headers = this.requestHeader(`Bearer ${this.oAuthService.getAccessToken()}`);
    return this.http.get<any>(`${AFTERSALES_ADMIN_API}/sellers/organizations/${sellerOrganizationId}`, {headers})
      .pipe(
        map(sellerInfo => {
          const sellerAddress = sellerInfo.addresses.filter(x => x.type === 'Default')[0];
          const address =
            `${sellerAddress.streetName ? `${sellerAddress.streetName}` : ''}` +
            `${sellerAddress.houseNumber ? ` ${sellerAddress.houseNumber}` : ''}` +
            `${sellerAddress.houseNumber || sellerAddress.streetName?', ': ' '}`+
            `${sellerAddress.addressLine2 ? `${sellerAddress.addressLine2}, ` : ''}` +
            `${sellerAddress.city ? `${sellerAddress.city}, ` : ''}` +
            `${sellerAddress.stateOrProvince ? `${sellerAddress.stateOrProvince} ` : ''}` +
            `${sellerAddress.postalCode ? `${sellerAddress.postalCode}, ` : ''}`+
            `${sellerAddress.country ? `${sellerAddress.country}` : ''}`;

          const data = {
            organizationName: sellerInfo?.name,
            firstName: sellerInfo?.contact?.firstName,
            lastName: sellerInfo?.contact?.lastName,
            phone: sellerInfo?.contact?.phone,
            email: sellerInfo?.contact?.email,
            address: address || 'N/A',
            shopName: sellerInfo?.shopName,
          };
          return data as TabsSellerInfo;
        }),
        shareReplay(1),
      );
  }

  private requestHeader(auth: string = ''): HttpHeaders {
    return new HttpHeaders({
      Authorization: auth,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }
}
