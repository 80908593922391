import { SidenavNode } from '~core/models';
import {
  CONSUMERS_ROUTE,
  SELLER_SUPPORT_ROUTE,
  ATTRIBUTES_ROUTE,
  ANONYMOUS_EMAIL_ROUTE,
  CATEGORY_TREE_ROUTE,
  EDIT_PRODUCTS,
  SEARCH_PRODUCTS,
  LOV_AND_UNITS_ROUTE,
  CONTENT_PROVIDER_RANKING_ROUTE,
  OFFER_DATA_UPLOADS,
  OFFERS_PAUSING_ROUTE,
  OFFERS_SEARCH_PRODUCT,
  ORDER_REFUNDS_ROUTE,
  ORDERLINES_ROUTE,
  ORDERS_ROUTE,
  PAYMENTS_ROUTE,
  PRODUCT_DATA_UPLOADS,
  SELLER_ACCOUNTS_ROUTE,
  SELLER_INVOICES_ROUTE,
  ACCOUNTING_ROUTE,
  BUYER_SUPPORT_ROUTE,
  SALES_ORDERS_ROUTE,
  EMAILS_ROUTE,
  PRODUCTS_ROUTE,
  OFFERS_ROUTE,
  PIM_ROUTE,
  DISCOUNT_ROUTE,
  DISCOUNT_ROUTE_OVERVIEW,
  DISCOUNT_ROUTE_REDEMPTION,
  DISCOUNT_ROUTE_CREATE,
  PAYOUTS_ROUTE,
  ACCOUNTS_ROUTE,
  SELLER_BALANCE_ROUTE,
  DISPUTES_ROUTE,
  DISPUTES_MANAGEMENT_ROUTE,
  DEBT_COLLECTION_DUNNING,
  DEBT_COLLECTION_RECONCILIATION,
  DEBT_COLLECTION,
  FeatureToggleEnum,
  PRODUCT_DATA_UPLOADS_V2,
  BRANDS_ROUTE,
  GENERAL_ATTRIBUTES_ROUTE,
} from '~shared/constants';
import { UserRolesEnum } from '~shared/model';

const BUYER_SUPPORT_TREE = [
  {
    title: 'Buyer Support',
    link: `/${BUYER_SUPPORT_ROUTE}`,
    navIcon: 'buyer',
    subTitle: 'Buyer',
    permissions: [UserRolesEnum.METROMARKETS_BUYER_ADMIN],
    children: [
      {
        title: 'BUYER_SUPPORT.NAVIGATION.BUSINESS.TITLE',
        link: `/${BUYER_SUPPORT_ROUTE}/${ACCOUNTS_ROUTE}`,
        icon: 'user',
        permissions: [UserRolesEnum.METROMARKETS_BUYER_ADMIN],
      },
      {
        title: 'BUYER_SUPPORT.NAVIGATION.CONSUMERS.TITLE',
        link: `/${BUYER_SUPPORT_ROUTE}/${CONSUMERS_ROUTE}`,
        icon: 'user',
        permissions: [UserRolesEnum.METROMARKETS_BUYER_ADMIN],
      },
    ],
  },
];

const ACCOUNTING_TREE = [
  {
    title: 'Accounting',
    link: `/${ACCOUNTING_ROUTE}`,
    navIcon: 'accounting',
    subTitle: 'Finance',
    permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
    children: [
      {
        link: `/${ACCOUNTING_ROUTE}/${SELLER_INVOICES_ROUTE}`,
        title: 'ACCOUNTING.NAVIGATION.INVOICES.TITLE',
        permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
        icon: 'invoices',
      },
      {
        link: `/${ACCOUNTING_ROUTE}/${PAYOUTS_ROUTE}`,
        title: 'ACCOUNTING.NAVIGATION.PAYOUT.TITLE',
        permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
        icon: 'payments',
      },
      {
        link: `/${ACCOUNTING_ROUTE}/${SELLER_BALANCE_ROUTE}`,
        title: 'ACCOUNTING.NAVIGATION.SELLER_BALANCE.TITLE',
        permissions: [UserRolesEnum.METROMARKETS_EMP_SELLER_BALANCE],
        icon: 'seller',
      },
    ],
  },
];

const SALES_ORDERS_TREE: SidenavNode[] = [
  {
    title: 'Sales Orders',
    link: `/${SALES_ORDERS_ROUTE}`,
    navIcon: 'order',
    subTitle: 'Orders',
    permissions: [UserRolesEnum.METROMARKETS_EMP_ORDERS],
    children: [
      {
        title: 'orders.NAVIGATION.ORDERS.TITLE',
        link: `/${SALES_ORDERS_ROUTE}/${ORDERS_ROUTE}`,
        icon: 'orders',
        permissions: [UserRolesEnum.METROMARKETS_EMP_ORDERS],
      },
      {
        title: 'orders.NAVIGATION.ORDER_LINE.TITLE',
        link: `/${SALES_ORDERS_ROUTE}/${ORDERLINES_ROUTE}`,
        icon: 'orders',
        permissions: [UserRolesEnum.METROMARKETS_EMP_ORDERS],
      },
    ],
  },
];

export const DEBT_RECOLLECTION_TREE = {
  title: 'PAYMENTS.NAVIGATION.DEBT_COLLECTION.TITLE',
  permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
  testTarget: 'debt-collection',
  link: `/${PAYMENTS_ROUTE}/${DEBT_COLLECTION}`,
  featureFlag: FeatureToggleEnum.ENABLE_DEBT_RECONCILIATION_MENU,
  children: [
    {
      link: `/${PAYMENTS_ROUTE}/${DEBT_COLLECTION}/${DEBT_COLLECTION_DUNNING}`,
      title: 'PAYMENTS.NAVIGATION.DEBT_COLLECTION.DUNNING.TITLE',
      icon: '',
      testTarget: 'dunning',
      permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
    },
    {
      link: `/${PAYMENTS_ROUTE}/${DEBT_COLLECTION}/${DEBT_COLLECTION_RECONCILIATION}`,
      title: 'PAYMENTS.NAVIGATION.DEBT_COLLECTION.RECONCILIATION.TITLE',
      icon: '',
      testTarget: 'reconciliation',
      permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
    },
  ],
};

const PAYMENTS_TREE: SidenavNode[] = [
  {
    title: 'Payments',
    link: `/${PAYMENTS_ROUTE}`,
    navIcon: 'payment',
    subTitle: 'Payments',
    permissions: [UserRolesEnum.METROMARKETS_EMP_ORDERS],
    children: [
      {
        title: 'PAYMENTS.NAVIGATION.PAYMENTS.TITLE',
        link: `/${PAYMENTS_ROUTE}/${PAYMENTS_ROUTE}`,
        icon: 'payments',
        permissions: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING],
      },
      {
        title: 'PAYMENTS.NAVIGATION.REFUND_REQUESTS.TITLE',
        link: `/${PAYMENTS_ROUTE}/${ORDER_REFUNDS_ROUTE}`,
        icon: 'returns',
        permissions: [UserRolesEnum.METROMARKETS_ACC_REQUEST_REFUND],
      },
      DEBT_RECOLLECTION_TREE,
    ],
  },
];

const ANONYMOUS_EMAIL_TREE: SidenavNode[] = [
  {
    title: 'Anonymous E-mails',
    link: `/${ANONYMOUS_EMAIL_ROUTE}`,
    navIcon: 'anonymous-email',
    subTitle: 'Email',
    permissions: [UserRolesEnum.METROMARKETS_ANONYMOUS_EMAILS],
    children: [
      {
        link: `/${ANONYMOUS_EMAIL_ROUTE}/${EMAILS_ROUTE}`,
        title: 'E-mails',
        permissions: [UserRolesEnum.METROMARKETS_ANONYMOUS_EMAILS],
        icon: 'mail',
      },
    ],
  },
];

const PRODUCTS_TREE = {
  title: 'Products',
  link: `/${SELLER_SUPPORT_ROUTE}/${PRODUCTS_ROUTE}`,
  permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
  testTarget: 'products',
  children: [
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PRODUCTS_ROUTE}/${PRODUCT_DATA_UPLOADS}`,
      title: 'SELLER_ADMIN.NAVIGATION.PRODUCT_DATA_UPLOADS.TITLE',
      icon: 'orders',
      testTarget: 'product-data-uploads',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
      featureFlag: FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V1,
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PRODUCTS_ROUTE}/${PRODUCT_DATA_UPLOADS_V2}`,
      title: 'SELLER_ADMIN.NAVIGATION.PRODUCT_DATA_UPLOADS_V2.TITLE',
      icon: 'orders',
      testTarget: 'product-data-uploads-v2',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
      featureFlag: FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V2,
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PRODUCTS_ROUTE}/${EDIT_PRODUCTS}/${SEARCH_PRODUCTS}`,
      title: 'SELLER_ADMIN.NAVIGATION.EDIT_PRODUCTS.TITLE',
      icon: 'store',
      testTarget: 'edit-products',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
  ],
};

const OFFERS_TREE = {
  title: 'Offers',
  link: `/${SELLER_SUPPORT_ROUTE}/${OFFERS_ROUTE}`,
  permissions: [UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN, UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
  children: [
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${OFFERS_ROUTE}/${OFFER_DATA_UPLOADS}`,
      title: 'SELLER_ADMIN.NAVIGATION.OFFER_DATA_UPLOADS.TITLE',
      icon: 'tag',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN],
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${OFFERS_ROUTE}/${OFFERS_PAUSING_ROUTE}/${OFFERS_SEARCH_PRODUCT}`,
      title: 'SELLER_ADMIN.NAVIGATION.OFFERS_PAUSING.TITLE',
      icon: 'tags-remove',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
  ],
};

const PIM_TREE = {
  title: 'SELLER_ADMIN.NAVIGATION.PIM.TITLE',
  link: `/${SELLER_SUPPORT_ROUTE}/${PIM_ROUTE}`,
  permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN, UserRolesEnum.METROMARKETS_SELLER_PIM_BRANDS],
  testTarget: 'pim',
  children: [
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PIM_ROUTE}/${CATEGORY_TREE_ROUTE}`,
      title: 'SELLER_ADMIN.NAVIGATION.CATEGORY_TREE.TITLE',
      icon: 'ppm',
      testTarget: 'category-tree',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PIM_ROUTE}/${BRANDS_ROUTE}`,
      title: 'SELLER_ADMIN.NAVIGATION.BRANDS.TITLE',
      icon: 'controller-record',
      testTarget: 'brands',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN, UserRolesEnum.METROMARKETS_SELLER_PIM_BRANDS],
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PIM_ROUTE}/${ATTRIBUTES_ROUTE}/${GENERAL_ATTRIBUTES_ROUTE}`,
      title: 'SELLER_ADMIN.NAVIGATION.ATTRIBUTES.TITLE',
      icon: 'controller-record',
      testTarget: 'attributes',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
    {
      link: `/${SELLER_SUPPORT_ROUTE}/${PIM_ROUTE}/${CONTENT_PROVIDER_RANKING_ROUTE}`,
      title: 'SELLER_ADMIN.NAVIGATION.CONTENT_PROVIDER_RANKING.TITLE',
      icon: 'content-provider-ranking',
      testTarget: 'CONTENT_PROVIDER_RANKING_ROUTE',
      permissions: [UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
  ],
};

const DISPUTE_TREE: SidenavNode[] = [
  {
    title: 'Disputes Management',
    link: `/${DISPUTES_MANAGEMENT_ROUTE}`,
    subTitle: 'Disputes',
    navIcon: 'user-chat-group',
    permissions: [UserRolesEnum.METROMARKETS_EMP_DISPUTES],
    children: [
      {
        title: 'DISPUTES.NAVIGATION.DISPUTES.TITLE',
        link: `/${DISPUTES_MANAGEMENT_ROUTE}/${DISPUTES_ROUTE}`,
        icon: 'user-chat-group',
        permissions: [UserRolesEnum.METROMARKETS_EMP_DISPUTES],
      },
    ],
  },
];

export const SELLER_SUPPORT_TREE = [
  {
    title: 'Seller Support',
    link: `/${SELLER_SUPPORT_ROUTE}`,
    navIcon: 'seller',
    subTitle: 'Seller',
    testTarget: 'seller-support',
    permissions: [
      UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN,
      UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN,
      UserRolesEnum.METROMARKETS_SELLER_PIM_BRANDS,
    ],
    children: [
      {
        link: `/${SELLER_SUPPORT_ROUTE}/${SELLER_ACCOUNTS_ROUTE}`,
        title: 'SELLER_ADMIN.NAVIGATION.ACCOUNTS_TITLE',
        icon: 'list-alt',
        permissions: [UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN],
      },
      PRODUCTS_TREE,
      OFFERS_TREE,
      PIM_TREE,
    ],
  },
];

const DISCOUNT_CODE_TREE: SidenavNode[] = [
  {
    title: 'Discount code',
    link: `/${DISCOUNT_ROUTE}`,
    navIcon: 'discount',
    subTitle: 'Discount',
    permissions: [UserRolesEnum.METROMARKETS_DISCOUNT_CODE_VIEW],
    children: [
      {
        link: `/${DISCOUNT_ROUTE}/${DISCOUNT_ROUTE_OVERVIEW}`,
        title: 'DISCOUNT.NAVIGATION.OVERVIEW.TITLE',
        icon: 'sale',
        permissions: [UserRolesEnum.METROMARKETS_DISCOUNT_CODE_VIEW],
      },
      {
        link: `/${DISCOUNT_ROUTE}/${DISCOUNT_ROUTE_REDEMPTION}`,
        title: 'DISCOUNT.NAVIGATION.REDEMPTION.TITLE',
        icon: 'sale',
        permissions: [UserRolesEnum.METROMARKETS_DISCOUNT_CODE_VIEW],
      },
      {
        link: `/${DISCOUNT_ROUTE}/${DISCOUNT_ROUTE_CREATE}`,
        title: 'DISCOUNT.NAVIGATION.CREATE_NEW.TITLE',
        icon: 'edit',
        permissions: [
          UserRolesEnum.METROMARKETS_DISCOUNT_CODE_CREATE_LVL_1,
          UserRolesEnum.METROMARKETS_DISCOUNT_CODE_CREATE_LVL_2,
          UserRolesEnum.METROMARKETS_DISCOUNT_CODE_CREATE_LVL_3,
        ],
      },
    ],
  },
];

export const getEmployeeTreeNav: SidenavNode[] = [
  ...ANONYMOUS_EMAIL_TREE,
  ...BUYER_SUPPORT_TREE,
  ...SELLER_SUPPORT_TREE,
  ...ACCOUNTING_TREE,
  ...SALES_ORDERS_TREE,
  ...DISCOUNT_CODE_TREE,
  ...PAYMENTS_TREE,
  ...DISPUTE_TREE,
];
