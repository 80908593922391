export const BUYER_SUPPORT_ROUTE = 'buyer-support';
export const ACCOUNTS_ROUTE = 'accounts';
export const CONSUMERS_ROUTE = 'consumers';

export const ACCOUNTING_ROUTE = 'accounting';
export const SELLER_INVOICES_ROUTE = 'invoices';
export const TRANSACTIONS_JOURNAL_ROUTE = 'journal';
export const FINANCIAL_POSTING_ROUTE = 'posting';
export const ORGANIZATIONS_ROUTE = 'organizations';
export const PAYOUTS_ROUTE = 'payouts';

export const SALES_ORDERS_ROUTE = 'sales-orders';
export const ORDERS_ROUTE = 'orders';
export const ORDERLINES_ROUTE = 'orderlines';

export const PAYMENTS_ROUTE = 'payments';
export const ORDER_REFUNDS_ROUTE = 'refund-requests';

export const ANONYMOUS_EMAIL_ROUTE = 'anonymous-email';
export const EMAILS_ROUTE = 'emails';

export const SELLER_SUPPORT_ROUTE = 'seller-support';
export const SELLER_ACCOUNTS_ROUTE = 'seller-accounts';
export const REPORTS_ROUTE = 'reports';

export const PRODUCTS_ROUTE = 'products';
export const PRODUCT_DATA_UPLOADS = 'product-data-uploads';
export const PRODUCT_DATA_UPLOADS_V2 = 'product-data-uploads-v2';
export const ROW_DATA_REVIEW_GRID_ROUTE = 'review-grid';
export const EDIT_PRODUCTS = 'edit-products';
export const EDIT_PRODUCT_ROUTE = 'edit-item';
export const ALL_PRODUCTS = 'all-products';
export const SEARCH_PRODUCTS = 'search-products';
export const INCOMPLETE_PRODUCTS = 'incomplete-products';
export const UNPUBLISHED_PRODUCTS = 'unpublished-products';

export const OFFERS_ROUTE = 'offers';
export const OFFER_DATA_UPLOADS = 'offer-data-uploads';
export const OFFERS_PAUSING_ROUTE = 'offers-pausing';
export const OFFERS_SEARCH_PRODUCT = 'product';
export const OFFERS_SEARCH_COMPANY = 'company';

export const PIM_ROUTE = 'pim';
export const CATEGORY_TREE_ROUTE = 'category-tree';
export const CREATE_ITEM_TYPE_ROUTE = 'new-item-type';
export const CREATE_CATEGORY_ROUTE = 'new-category';
export const EDIT_ITEM_TYPE_ROUTE = 'edit-item-type';
export const EDIT_CATEGORY_ROUTE = 'edit-category';
export const ATTRIBUTES_ROUTE = 'attributes';
export const GENERAL_ATTRIBUTES_ROUTE = 'general-attributes';
export const CREATE_ATTRIBUTE_ROUTE = 'new';
export const LOV_AND_UNITS_ROUTE = 'list-of-values-and-units';
export const CONTENT_PROVIDER_RANKING_ROUTE = 'content-provider-ranking-list';
export const LOV_ROUTE = 'lov';
export const BRANDS_ROUTE = 'brands';
export const UNIT_GROUPS_ROUTE = 'unit-groups';
export const CREATE_LOV_ROUTE = 'new-lov';
export const EDIT_LOV_ROUTE = 'edit-lov';
export const EDIT_ATTRIBUTE_ROUTE = 'edit-attribute';
export const CREATE_BRAND_ROUTE = 'new-brand';
export const DISCOUNT_ROUTE = 'discount';
export const DISCOUNT_ROUTE_OVERVIEW = 'overview';
export const DISCOUNT_ROUTE_REDEMPTION = 'redemption';
export const DISCOUNT_ROUTE_CREATE = 'create';

export const LEGACY_DASHBOARD_ROUTE = 'dashboard';
export const LEGACY_ADMIN_PANEL_ROUTE = 'admin-panel';

export const SELLER_BALANCE_ROUTE = 'seller-balance';

export const DISPUTES_MANAGEMENT_ROUTE = 'disputes-management';
export const DISPUTES_ROUTE = 'disputes';
export const DISPUTE_CONVERSATION = 'conversation';
export const DISPUTE_CASE_DETAILS = 'case-details';
export const DEBT_COLLECTION = 'debt-collection';
export const DEBT_COLLECTION_DUNNING = 'dunning';
export const DEBT_COLLECTION_RECONCILIATION = 'reconciliation';
